import './job.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import { initFlowbite } from 'flowbite';
import { roles, accessToken, AuthVerify, apiBaseUrl } from '../../modules/main';
import profileDefault from '../../modules/images/profile.jpg'

const Post = ({ setPostsCount, setBreadcrumb, setBreadcrumbLink, setErrorMessage, setSuccessMessage, setNetworkErrorMessage }) => {
  useEffect(() => {
    setBreadcrumb(['Post']);
    setBreadcrumbLink(['#']);
  }, [setBreadcrumb, setBreadcrumbLink]);
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [images, setImages] = useState(null);
  const [postProfileName, setPostProfileName] = useState('');
  const [postProfileBadge, setPostProfileBadge] = useState('');
  const [postProfileImage, setPostProfileImage] = useState('');
  const [decision, setDecision] = useState('');
  const { jobId } = useParams()

  useEffect(() => {
    initFlowbite()
  }, [images])

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        if (AuthVerify()) {
          const response = await axios.get(`${apiBaseUrl}/moderator/jobs/${jobId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          if (!response.data.error) {
            setPost(response.data.jobs);
            setDecision(response.data.jobs.decision);
            fetchPostProfile(response.data.jobs.of)
          } else {
            setErrorMessage(response.data.message);
          }
        } else {
          navigate('/logout');
        }
      } catch (error) {
        if (error.message === 'Network Error') {
          setNetworkErrorMessage('No Internet Connection');
        } else {
          setErrorMessage(error.message);
        }
      }
    };
    fetchPosts();
  }, [accessToken, apiBaseUrl]);

  const fetchPostProfile = async (id) => {
    try {
      await AuthVerify().then(async response => {
        if (response) {
          const response = await axios.post(`${apiBaseUrl}/shortProfile`,
            {
              id,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
          let data = await response.data.profile;
          setPostProfileName(data.name);
          setPostProfileBadge(data.badge);
          setPostProfileImage(data.image.publicURL);
        } else {
          navigate('/logout');
        }
        return true
      });
    } catch (error) {
      if (error.message === 'Network Error') {
        setNetworkErrorMessage('No Internet Connection');
      } else {
        // navigate('/dashboard');
        console.log(`Error: ${error.message}`);
      }
    }
  };

  const nextPost = async () => {
    try {
      await AuthVerify().then(async response => {
        if (response) {
          let jobsArray = [];
          if (roles.includes("moderator")) {
            // Refresh the jobs after adding a new one
            const response = await axios.get(`${apiBaseUrl}/moderator/jobs`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
            jobsArray = jobsArray.concat(response.data.jobs);
            if (jobsArray.length !== 0) {
              navigate(`/dashboard/jobs/${jobsArray[0]._id}`);
              setPostsCount(jobsArray.length);
            } else {
              navigate(`/dashboard/jobs/complete`);
              setPostsCount(null);
            }
          }
          if (roles.includes("admin")) {
            // Refresh the jobs after adding a new one
            const response = await axios.get(`${apiBaseUrl}/admin/jobs`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
            jobsArray = jobsArray.concat(response.data.jobs);
            jobsArray = jobsArray.concat(response.data.jobsPass);
            if (jobsArray.length !== 0) {
              navigate(`/dashboard/jobs/${jobsArray[0]._id}`);
              setPostsCount(jobsArray.length);
            } else {
              navigate(`/dashboard/jobs/complete`);
              setPostsCount(null);
            }
          }
          setDecision('');
        }
      });
    } catch (error) {
      if (error.message === 'Network Error') {
        setNetworkErrorMessage("Network Error.")
      } else {
        console.error('Error adding post:', error);
      }
    }
  };

  const handleApproval = async () => {
    try {
      await AuthVerify().then(async response => {
        if (response) {
          // Make a request to add a new post
          await axios.patch(
            `${apiBaseUrl}/moderator/jobs`,
            {
              jobId,
              decision: decision,
              status: 'active'
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          setErrorMessage('Status Updated');
          setDecision('');
          nextPost();
        }
      });
    } catch (error) {
      if (error.message === 'Network Error') {
        setNetworkErrorMessage("Network Error.")
      } else {
        console.error('Error adding post:', error);
      }
    }
  };

  const handleReject = async () => {
    try {
      await AuthVerify().then(async response => {
        if (response) {
          if (decision != '') {

            // Make a request to add a new post
            await axios.patch(
              `${apiBaseUrl}/moderator/jobs`,
              {
                jobId,
                decision,
                status: 'blocked'
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            setErrorMessage('Status Updated');
            setDecision('');
            nextPost();
          } else {
            setErrorMessage('Provide Reason(s)');
          }
        }
      });
    } catch (error) {
      if (error.message === 'Network Error') {
        setNetworkErrorMessage("Network Error.")
      } else {
        console.error('Error adding post:', error);
      }
    }
  };

  const handleForward = async () => {
    try {
      await AuthVerify().then(async response => {
        if (response) {
          if (decision != '') {

            // Make a request to add a new post
            await axios.patch(
              `${apiBaseUrl}/moderator/jobs`,
              {
                jobId,
                decision,
                passToAdmin: true
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            setErrorMessage('Status Updated');
            setDecision('');
            nextPost();
          } else {
            setErrorMessage('Provide Reason(s)');
          }
        }
      });
    } catch (error) {
      if (error.message === 'Network Error') {
        setNetworkErrorMessage("Network Error.")
      } else {
        console.error('Error adding post:', error);
      }
    }
  };

  if (!post) return (
    <>
      <div class="flex items-center justify-center w-full h-[90vh]">
        <div role="status">
          <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </>
  )

  return (
    <>
      <div className="bg-gray-100 flex justify-center items-center">
        <div class="grid grid-cols-1 md:grid-cols-2 bg-white">
          <div className="container flex justify-center">
            <div className="md:max-w-sm w-100">
              <div className="bg-white relative shadow-lg hover:shadow-xl transition duration-1500 rounded-lg">

                <div
                  className="scrollbar overflow-y-auto scrollbar-thumb-rounded-full scrollbar-track-rounded-full rounded-lg  md:max-h-[90vh] h-[92vh] max-h-[92vh]">
                  <div className="px-6 pt-3 mb-2 rounded-lg bg-white">
                    <h1 className="text-gray-900 font-bold text-2xl mb-2 hover:cursor-default">{post.title}</h1>

                    <span className="align-middle inline-flex items-center rounded-full bg-orange-50 px-2 py-1 text-sm font-medium text-orange-800">
                      {post.subCategory}
                    </span>
                  </div>

                  <div className='flex gap-2 px-6' >
                    <div>
                      <div className="inline-flex rounded-full" role="group">
                        <button type="button"
                          className="rounded-l-full border border-gray-200 bg-white text-xs font-medium px-4 py-2 text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:text-blue-700">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                            stroke="currentColor" className="w-5 h-5 inline">
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                          </svg><span className='ml-2'>Like</span>
                        </button>
                        <button type="button"
                          className="rounded-r-full border border-gray-200 bg-white text-xs font-medium px-4 py-2 text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:text-blue-700">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                            stroke="currentColor" className="w-5 h-5 inline">
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                          </svg><span className='ml-2'>Share</span>
                        </button>
                      </div>
                    </div>
                    <div>
                      <span type="button" class="py-2.5 px-5 space-x-4 me-2 mb-2 text-xs font-medium inline text-gray-900 bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                        <span className=''>{post.views} views</span>
                      </span>
                    </div>
                  </div>
                  <div className="px-6 pt-3 rounded-lg bg-white">
                    <p className="text-gray-700 mb-20 tracking-wide rounded-lg hover:cursor-default">
                      {post.description}
                    </p>
                  </div>
                </div>
                <div className="absolute w-full bottom-3 rounded-lg">
                  <div
                    className="items-center w-100 mx-5 p-3 border text-gray-500 bg-white rounded-lg shadow-md dark:text-gray-400 dark:divide-gray-700 dark:bg-gray-800 hover:cursor-pointer">
                    <div className="grid grid-cols-9">
                      <div className="col-span-1">
                        <img className="w-8 h-8 rounded-full ring-2 ring-gray-300 dark:ring-gray-500 object-cover"
                          src={(postProfileImage)?(postProfileImage):(profileDefault)}
                          alt="Profile" />
                      </div>
                      <div className="col-span-8">
                        <div className=" ml-2 w-100">
                          <span className="align-middle text-gray-800 font-bold text-lg w-100 overflow-hidden">
                            {postProfileName}
                          </span>
                          <span
                            className="align-middle ml-2 inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">{postProfileBadge}</span>

                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 float-right">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                          </svg>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="container p-3 justify-center">
            <div className="md:max-w-sm w-100">
              <div className='mb-4'>
                <textarea onChange={(e) => setDecision(e.target.value)} value={decision} id="message" rows="4" class="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Reason(s) / Comments"></textarea>
              </div>
              <div className='grid mb-4'>
                <button type="button" onClick={handleApproval} class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Approve Post</button>
                <button type="button" onClick={handleReject} class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Disapprove Post</button>
                {roles.includes("moderator") ? (
                  <button type="button" onClick={handleForward} class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900">Forward to Admin</button>
                ) : (<></>)}

              </div>
              <div id="dropdown-cta" class="p-4 mt-6 rounded-lg bg-blue-50 dark:bg-blue-900" role="alert">
                <div class="flex items-center mb-3">
                  <span class="text-orange-800 text-sm font-semibold me-2 px-2.5 py-0.5 rounded dark:bg-orange-200 dark:text-orange-900">Instructions</span>
                  <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-blue-50 inline-flex justify-center items-center w-6 h-6 text-blue-900 rounded-lg focus:ring-2 focus:ring-blue-400 p-1 hover:bg-blue-200 h-6 w-6 dark:bg-blue-900 dark:text-blue-400 dark:hover:bg-blue-800" data-dismiss-target="#dropdown-cta" aria-label="Close">
                    <span class="sr-only">Close</span>
                    <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                  </button>
                </div>
                <ul class="max-w-md space-y-1 px-3 list-disc list-inside mb-3 text-sm text-blue-800 dark:text-blue-400">
                  <li>
                    At least 10 characters (and up to 100 characters)
                  </li>
                  <li>
                    At least one lowercase character
                  </li>
                  <li>
                    Inclusion of at least one special character.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Post;