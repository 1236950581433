import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { roles, accessToken, apiBaseUrl, AuthVerify } from '../../modules/main';
import axios from 'axios';
import './settings.css';
import profileDefault from '../../modules/images/profile.jpg'

const Settings = ({ setBreadcrumb, setBreadcrumbLink, setErrorMessage, setSuccessMessage, setNetworkErrorMessage }) => {
  useEffect(() => {
    setBreadcrumb(['Settings']);
    setBreadcrumbLink(['/dashboard/settings']);
  }, [setBreadcrumb, setBreadcrumbLink]);
  const navigate = useNavigate();
  const [moderatorsName, setModeratorsName] = useState('');
  const [moderatorsUID, setModeratorsUID] = useState('');
  const [moderatorsEmail, setModeratorsEmail] = useState('');
  useEffect(() => {
    const fetchShortProfile = async () => {
      try {
        await AuthVerify().then(async res => {
          if (res) {
            const response = await axios.get(`${apiBaseUrl}/moderator/profile`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });
            const data = response.data.finalProfile;
            setModeratorsName(data.name);
            setModeratorsUID(data.uid);
            setModeratorsEmail(data.email);
          } else {
            navigate('/logout');
          }
          return true
        });
      } catch (error) {
        if (error.message === 'Network Error') {
          setNetworkErrorMessage("Network Error.")
        } else {
          console.error('Error fetching profiles:', error);
        }
      }
    }

    fetchShortProfile();
  }, [accessToken, apiBaseUrl, navigate]);
  return (
    <>
      <div class="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 dark:bg-gray-900">
        <div class="p-4 h-fit mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
          <div class="items-center sm:flex xl:block 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
            <img class="mb-4 rounded-lg w-28 h-28 sm:mb-0 xl:mb-4 2xl:mb-0" src={profileDefault} alt="Jese picture" />
            <div>
              <h3 class="mb-3 text-xl font-bold text-gray-900 dark:text-white">{moderatorsName} </h3>

              <div class="flex items-center space-x-4">
                <Link to={'/dashboard/settings/changepassword'} class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                  Change Password
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-2">
          <div class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <h3 class="mb-4 text-xl font-semibold dark:text-white">Profile</h3>
            <form action="#">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                  <input value={moderatorsName} type="text" name="name" id="name" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" placeholder="Aditya Dubey" disabled={true} />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label for="uid" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">UID</label>
                  <input value={moderatorsUID} type="number" name="uid" id="uid" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" placeholder="60000" disabled={true} />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                  <input value={moderatorsEmail} type="email" name="email" id="email" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" placeholder="example@company.com" disabled={true} />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label for="roles" class="block mb-2  text-sm font-medium text-gray-900 dark:text-white">Roles</label>
                  <input value={roles} type="text" name="roles" id="roles" class="shadow-sm capitalise bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" placeholder="example@company.com" disabled={true} />

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;
