import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { roles, accessToken, apiBaseUrl, AuthVerify } from '../../modules/main';
import axios from 'axios';
import profileDefault from '../../modules/images/profile.jpg'
import './editProfile.css';
import { initFlowbite } from 'flowbite';

const EditProfile = ({ setPostsCount, setBreadcrumb, setBreadcrumbLink, setErrorMessage, setSuccessMessage, setNetworkErrorMessage }) => {
  const { profileId } = useParams();

  useEffect(() => {
    setBreadcrumb(['Manage Staff', 'Edit Profile']);
    setBreadcrumbLink(['/dashboard/managestaff', '/dashboard/managestaff/createuser']);
  }, [setBreadcrumb, setBreadcrumbLink]);

  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [uid, setUid] = useState('');
  const [email, setEmail] = useState('');
  const [userRoles, setUserRoles] = useState([]);
  const [proceeding, setProceeding] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [status, setStatus] = useState(null);
  const [admins, setAdmins] = useState([]);
  const [posts, setPosts] = useState([]);
  const [moderatorsNotNull, setModeratorsNotNull] = useState(true);
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    if (sessionStorage.getItem('status') === 'pending') {
      navigate('/dashboard/settings/setpassword');
    }
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(intervalId);

  }, []);

  const calculateTimeLeft = (createdAt) => {
    const createdTime = new Date(createdAt).getTime();
    const elapsedMilliseconds = currentTime - createdTime;
    const remainingMilliseconds = Math.max(0, 12 * 60 * 60 * 1000 - elapsedMilliseconds); // Assuming 12 hours for post moderation

    const hours = Math.floor(remainingMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((remainingMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((remainingMilliseconds % (1000 * 60)) / 1000);

    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (accessToken === null) {
      navigate('/');
    }
    if (sessionStorage.getItem('status') === 'pending') {
      navigate('/dashboard');
    }
  }, [navigate])

  useEffect(() => {
    for (let i = 0; i < userRoles.length; i++) {
      const e = userRoles[i];
      document.getElementById(e).checked = true;
      if (e == 'moderator') {
        document.getElementById("adminValue").classList.remove("hidden");
      }
    }

  }, [userRoles])

  const changeRole = (value) => {
    let roles = userRoles;
    if (roles.includes(value)) {
      roles.splice(userRoles.indexOf(value), 1);
    } else {
      roles.push(value);
    }
    setUserRoles(roles);

    if (roles.includes('moderator')) {
      document.getElementById("adminValue").classList.remove("hidden");
    } else {
      document.getElementById("adminValue").classList.add("hidden");
    }
  }


  useEffect(() => {
    initFlowbite()
  }, [posts])

  useEffect(() => {
    if (sessionStorage.getItem('status') !== 'pending') {
      const fetchPosts = async () => {
        try {
          await AuthVerify().then(async response => {
            if (response) {
              let postsArray = [];
              const response = await axios.post(`${apiBaseUrl}/post/assigned`,
                {
                  id: profileId
                }, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });
              postsArray = response.data.posts;
              setPosts(postsArray);
              if (postsArray.length < 1) {
                setModeratorsNotNull(false)
              }
              setPosts(postsArray);
              if (postsArray.length < 1) {
                setModeratorsNotNull(false)
              }
          } else {
            navigate('/logout');
          }
          });
} catch (error) {
  if (error.message === 'Network Error') {
    setNetworkErrorMessage('No Internet Connection');
  } else { console.error(`Error: ${error.message}`); }
}
      };

fetchPosts();
    }
  }, [accessToken, apiBaseUrl, userRoles]);

useEffect(() => {
  if (sessionStorage.getItem('status') !== 'pending') {
    const fetchPosts = async () => {
      try {
        await AuthVerify().then(async response => {
          if (response) {
            const response = await axios.get(`${apiBaseUrl}/moderator/admin`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
            setAdmins(response.data.moderators);
          } else {
            navigate('/logout');
          }
        });
      } catch (error) {
        if (error.message === 'Network Error') {
          setNetworkErrorMessage("Network Error.")
        } else { console.error(`Error: ${error.message}`); }
      }
    };

    fetchPosts();
  }
}, [accessToken, apiBaseUrl]);

useEffect(() => {
  if (sessionStorage.getItem('status') !== 'pending') {
    const fetchPosts = async () => {
      try {
        await AuthVerify().then(async response => {
          if (response) {
            const response = await axios.post(`${apiBaseUrl}/moderator/profile`,
              {
                id: profileId
              }, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
            setName(response.data.profile.name);
            setUid(response.data.profile.uid);
            setEmail(response.data.profile.email);
            roles.includes("superAdmin") ? (setUserRoles(response.data.profile.roles)) : (<></>);
            roles.includes("superAdmin") ? (setAdmin(response.data.profile.admin)) : (<></>);
            setStatus(response.data.profile.status);
          } else {
            navigate('/logout');
          }
        });
      } catch (error) {
        if (error.message === 'Network Error') {
          setNetworkErrorMessage("Network Error.")
        } else { console.error(`Error: ${error.message}`); }
      }
    };

    fetchPosts();
  }
}, [accessToken, apiBaseUrl]);

const createUser = async () => {
  try {
    if (name !== '' && uid !== '' && status !== '' && userRoles.length && uid.toString().length === 5) {
      setProceeding(true)
      await AuthVerify().then(async response => {
        if (response) {
          let res = await axios.patch(
            `${apiBaseUrl}/moderator/`,
            {
              id: profileId,
              name,
              uid,
              email,
              admin,
              status,
              roles: userRoles
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          if (!res.data.error) {
            setSuccessMessage('Profile successfully updated.');
            navigate('/dashboard/managestaff');
          } else {
            setProceeding(null);
            setErrorMessage(res.data.message);
          }
        }

      });
    } else {
      setErrorMessage(`Please provide roles.`);
    }
  } catch (error) {
    setProceeding(null);
    if (error.message === 'Network Error') {
      setNetworkErrorMessage("Network Error.")
    } else {
      console.error('Error adding post:', error);
    }
  }
};

const handelCreateUser = (event) => {
  event.preventDefault();
  createUser();
};
return (
  <>
    <div class="p-4 bg-white sm:p-6 dark:bg-gray-800">
      <div class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
        <h3 class="mb-4 text-xl font-semibold dark:text-white">Edit Profile</h3>
        <form method='post' onSubmit={handelCreateUser}>
          <div class="grid grid-cols-6 gap-6">
            <div class="col-span-6 sm:col-span-3">
              <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
              <input onChange={(e) => setName(e.target.value)} value={name} type="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Aditya Dubey" required />
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label for="uid" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">UID</label>
              <input min={10000} max={99999} autoComplete="off" onChange={(e) => setUid(e.target.value)} value={uid} type="number" name="confirm-name" id="confirm-name" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" placeholder="60000" required />
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
              <input autoComplete="off" onChange={(e) => setEmail(e.target.value)} value={email} type="email" name="confirm-name" id="confirm-name" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" placeholder="name@example.com" required />
            </div>
            {roles.includes("superAdmin") ? (
              <>
                <div class="col-span-6 sm:col-span-3">
                  <label for="roles" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Roles</label>
                  <div class="inline mr-5 items-center mb-4">
                    <input id='moderator' onChange={(e) => changeRole(e.target.value)} type="checkbox" value="moderator" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label for="moderator" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Moderator</label>
                  </div>

                  <div class="inline mr-5 items-center mb-4">
                    <input id='admin' onChange={(e) => changeRole(e.target.value)} type="checkbox" value="admin" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label for="admin" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Admin</label>
                  </div>
                  <div class="inline mr-5 items-center mb-4">
                    <input id='superAdmin' onChange={(e) => changeRole(e.target.value)} type="checkbox" value="superAdmin" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label for="superAdmin" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Super Admin</label>
                  </div>
                </div>
                <div id="adminValue" class="hidden col-span-6 sm:col-span-3">
                  <label for="admins" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Assign Admin</label>

                  <select id='admins' onChange={(e) => setAdmin(e.target.value)} value={admin} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required={(userRoles.includes('moderator')) ? (true) : (false)}>
                    <option value={''} selected>Choose a Admin</option>
                    {admins.map((admin) => (
                      <option key={admin._id} value={admin._id}>{admin.name}</option>
                    ))}
                  </select>
                </div>
              </>) : (<></>)}
            {(status !== 'pending') ? (
              <>
                <div class="col-span-6 sm:col-span-3">
                  <label for="status" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status</label>

                  <select id="status" onChange={(e) => setStatus(e.target.value)} value={status} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                    <option value='pending'>Choose Status</option>
                    <option value='active'>Active</option>
                    <option value='blocked'>Blocked</option>
                  </select>
                </div>

              </>) : (<></>)}
            <div class="col-span-6 sm:col-full">
              <button typeof='submt' class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800" type="submit">
                {proceeding ? (<>
                  <div className='inline mr-3' role="status">
                    <svg aria-hidden="true" class="w-5 h-5 inline text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </>) : (<></>)}
                Edit Profile
              </button>
            </div>
          </div>
        </form>
      </div >

      <div class="items-center justify-between lg:flex">
        <div class="mb-4 lg:mb-0">
          <h3 class="mb-2 text-xl font-bold text-gray-900 dark:text-white">Post<small>(s)</small> Allocated</h3>
          {/* <span class="text-base font-normal text-gray-500 dark:text-gray-400">This is a list of latest transactions</span> */}
        </div>
      </div>

      <div class="flex flex-col mt-6">
        <div class="overflow-x-auto shadow rounded-lg">
          <div class="inline-block min-w-full align-middle">
            <div class="overflow-hidden sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                <thead class="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                      #
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                      Action
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                      Status
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                      Time Left
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white dark:bg-gray-800">
                  {posts.map((post, index) => (
                    <tr key={post._id}>
                      <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                        <Link to={{ pathname: `/dashboard/post/${post._id}` }}>
                          <span class="font-semibold">{index + 1}</span>
                        </Link>
                      </td>
                      <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                        <Link to={{ pathname: `/dashboard/post/${post._id}` }}>
                          <button type="button" class="py-2 px-5 me-2 mb-2 text-xs text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Open Post</button>
                        </Link>
                      </td>
                      <td class="p-4 whitespace-nowrap">
                        <span class="rounded-full capitalize bg-orange-100 text-orange-800 text-xs font-medium mr-2 px-2.5 py-0.5 dark:bg-gray-700 dark:text-orange-400 border border-orange-100 dark:border-orange-500">{post.passToAdmin ? ('Forward') : (post.status)}</span>
                      </td>
                      <td class="p-4 text-sm font-normal text-orange-500 whitespace-nowrap dark:text-orange-400">
                        {calculateTimeLeft(post.createdAt)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {(!moderatorsNotNull) ? (<div className='capitalize w-100 text-center text-orange-800 text-sm font-medium mr-2 px-5 py-2 dark:text-orange-400'>No posts Allocated.</div>) : (<></>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)
};

EditProfile.propTypes = {};

EditProfile.defaultProps = {};

export default EditProfile;
