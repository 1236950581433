import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { isLoggedIn } from './modules/main';
import React, { useState } from 'react';

import Login from "./components/login/login";
import Dashboard from './components/dashboard/dashboard';
import Logout from './components/logout/logout';
import NotFound from './components/notFound/notFound';
import Post from './components/post/post';
import Jobs from './components/jobs/job';
import AddPost from "./components/addPost/addPost";
import Moderate from "./components/moderate/moderate";
import ModerateJobs from "./components/moderateJobs/moderateJobs";
import ManageStaff from "./components/manageStaff/manageStaff";
import EditProfile from "./components/editProfile/editProfile";
import AddUser from "./components/addUser/addUser";
import AllDone from "./components/allDone/allDone";
import AllDoneJobs from "./components/allDoneJobs/allDone";
import NewUser from "./components/newUser/newUser";
import Otp from "./components/otp/otp";
import Settings from "./components/settings/settings";
import ChangePassword from "./components/changePassword/changePassword";
import SetPassword from "./components/setPassword/setPassword";

const App = () => {
  const [postsCount, setPostsCount] = useState(null);
  const [jobsCount, setJobsCount] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [token, setToken] = useState(null);
  const [status, setStatus] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [breadcrumbLink, setBreadcrumbLink] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [networkErrorMessage, setNetworkErrorMessage] = useState('');
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={isLoggedIn() ? (
          <Navigate to={"/dashboard"} />
        ) : (
          <Navigate to={"/login"} />
        )}></Route>
        <Route path="/login" element={isLoggedIn() ? (
          <Navigate to={"/dashboard"} />
        ) : (
          <Login />
        )}></Route>
        <Route path="/emailLogin" element={isLoggedIn() ? (
          <Navigate to={"/dashboard"} />
        ) : (
          <NewUser
          setToken={setToken}
          setStatus={setStatus}
          />
        )}>
        </Route>

        <Route path="/emailLogin/otp" element={isLoggedIn() ? (
          <Navigate to={"/dashboard"} />
        ) : (
          <Otp
          token={token}
          />
        )}></Route>
        <Route path="/dashboard" element={isLoggedIn() ? (
          <Dashboard
            postsCount={postsCount}
            jobsCount={jobsCount}
            status={status}
            breadcrumb={breadcrumb}
            breadcrumbLink={breadcrumbLink}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setNetworkErrorMessage={setNetworkErrorMessage}
            errorMessage={errorMessage}
            successMessage={successMessage}
            networkErrorMessage={networkErrorMessage}
          />
        ) : (
          <Navigate to={"/login"} />
        )}>
          <Route path='/dashboard' element={<Moderate
            setPostsCount={setPostsCount}
            setJobsCount={setJobsCount}
            jobsCount={jobsCount}
            setJobs={setJobs}
            setBreadcrumb={setBreadcrumb}
            setBreadcrumbLink={setBreadcrumbLink}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setNetworkErrorMessage={setNetworkErrorMessage}
          />}></Route>
          <Route path='/dashboard/jobs' element={<ModerateJobs
            setJobsCount={setJobsCount}
            setJobs={setJobs}
            jobs={jobs}
            setBreadcrumb={setBreadcrumb}
            setBreadcrumbLink={setBreadcrumbLink}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setNetworkErrorMessage={setNetworkErrorMessage}
          />}></Route>
          <Route path='managestaff' element={<ManageStaff
            setPostsCount={setPostsCount}
            setBreadcrumb={setBreadcrumb}
            setBreadcrumbLink={setBreadcrumbLink}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setNetworkErrorMessage={setNetworkErrorMessage}
          />}></Route>
          <Route path='managestaff/createuser' element={<AddUser
            setPostsCount={setPostsCount}
            setBreadcrumb={setBreadcrumb}
            setBreadcrumbLink={setBreadcrumbLink}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setNetworkErrorMessage={setNetworkErrorMessage}
          />}></Route>
          <Route path='managestaff/editprofile/:profileId' element={<EditProfile
            setPostsCount={setPostsCount}
            setBreadcrumb={setBreadcrumb}
            setBreadcrumbLink={setBreadcrumbLink}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setNetworkErrorMessage={setNetworkErrorMessage}
          />}></Route>
          <Route path="profile/post" element={<AddPost
            setBreadcrumb={setBreadcrumb}
            setBreadcrumbLink={setBreadcrumbLink}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setNetworkErrorMessage={setNetworkErrorMessage}
            />}></Route>
          <Route path="post/complete" element={<AllDone />}></Route>
          <Route path="jobs/complete" element={<AllDoneJobs />}></Route>
          <Route path='post/:postId' element={<Post
            setPostsCount={setPostsCount}
            setBreadcrumb={setBreadcrumb}
            setBreadcrumbLink={setBreadcrumbLink}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setNetworkErrorMessage={setNetworkErrorMessage}
          />}></Route>
          <Route path='jobs/:jobId' element={<Jobs
            setPostsCount={setPostsCount}
            setBreadcrumb={setBreadcrumb}
            setBreadcrumbLink={setBreadcrumbLink}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setNetworkErrorMessage={setNetworkErrorMessage}
          />}></Route>
          <Route path="settings" element={<Settings
            setBreadcrumb={setBreadcrumb}
            setBreadcrumbLink={setBreadcrumbLink}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setNetworkErrorMessage={setNetworkErrorMessage}
            />}></Route>
          <Route path="settings/changepassword" element={<ChangePassword
            setBreadcrumb={setBreadcrumb}
            setBreadcrumbLink={setBreadcrumbLink}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setNetworkErrorMessage={setNetworkErrorMessage}
            />}></Route>
          <Route path="settings/setpassword" element={<SetPassword
            setBreadcrumb={setBreadcrumb}
            setBreadcrumbLink={setBreadcrumbLink}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setNetworkErrorMessage={setNetworkErrorMessage}
            />}></Route>
        </Route>
        <Route path='/logout' element={<Logout />}></Route>
        <Route path='*' element={<NotFound />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;