import './moderate.css';
import { initFlowbite } from 'flowbite';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { roles, accessToken, AuthVerify, apiBaseUrl } from '../../modules/main';

const Moderate = ({ jobs, setJobsCount, setBreadcrumb, setBreadcrumbLink, setErrorMessage, setSuccessMessage, setNetworkErrorMessage }) => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [moderatorsName, setModeratorsName] = useState('');
  const [moderatorsUID, setModeratorsUID] = useState('');
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    setPosts(jobs)
    setBreadcrumb(['Dashboard', 'Jobs']);
    setBreadcrumbLink(['/dashboard']);
  }, [setBreadcrumb, setBreadcrumbLink]);

  useEffect(() => {
    if (sessionStorage.getItem('status') === 'pending') {
      navigate('/dashboard/settings/setpassword');
    }
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000); // Update current time every second

    return () => clearInterval(intervalId); // Cleanup interval on component unmount

  }, []);

  const calculateTimeLeft = (createdAt) => {
    const createdTime = new Date(createdAt).getTime();
    const elapsedMilliseconds = currentTime - createdTime;
    const remainingMilliseconds = Math.max(0, 12 * 60 * 60 * 1000 - elapsedMilliseconds); // Assuming 12 hours for post moderation

    const hours = Math.floor(remainingMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((remainingMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((remainingMilliseconds % (1000 * 60)) / 1000);

    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    initFlowbite()
  }, [posts])

  useEffect(() => {
    if (sessionStorage.getItem('status') !== 'pending') {
      const fetchJobs = async () => {
        try {
          await AuthVerify().then(async response => {
            if (response) {
              let postsArray = [];
              if (roles.includes("moderator")) {
                const responseJobs = await axios.get(`${apiBaseUrl}/moderator/jobs`, {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                });
                postsArray = postsArray.concat(responseJobs.data.jobs);
                setPosts(postsArray);
                if (!roles.includes("admin")) {
                  if (postsArray.length === 0) { navigate('/dashboard/jobs/complete'); setJobsCount(null); }
                  else { setJobsCount(postsArray.length); }
                }
              }
              if (roles.includes("admin")) {
                const responseAdminJobs = await axios.get(`${apiBaseUrl}/admin/jobs`, {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                });
                postsArray = postsArray.concat(responseAdminJobs.data.jobs);
                postsArray = postsArray.concat(responseAdminJobs.data.jobsPass);
                setPosts(postsArray);
                if (roles.includes("admin")) {
                  if (postsArray.length === 0) { navigate('/dashboard/jobs/complete'); setJobsCount(null); }
                  else { setJobsCount(postsArray.length); }
                }
              }
            } else {
              navigate('/logout');
            }
          });
        } catch (error) {
          if (error.message === 'Network Error') {
            setNetworkErrorMessage('No Internet Connection');
          } else { console.error(`Error: ${error.message}`); }
        }
      };

      fetchJobs();
    }
  }, [accessToken, apiBaseUrl]);

  const showShortProfile = (e) => {
    let id = e.currentTarget.getAttribute("data-moderator");
    setModeratorsName('Loading...');
    setModeratorsUID('...');
    fetchModerator(id);
  }
  const fetchModerator = async (id) => {
    try {
      await AuthVerify().then(async response => {
        if (response) {
          const response = await axios.post(`${apiBaseUrl}/moderator/shortprofile`,
            {
              id,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
          let data = await response.data;
          setModeratorsName(data.name);
          setModeratorsUID(data.uid);
        } else {
          navigate('/logout');
        }
        return true
      });
    } catch (error) {
      console.error('Error fetching profiles:', error);
      if (error.message === "Network Error") {
        setNetworkErrorMessage('No Internet Connection');
      } else { console.error(`Error: ${error.message}`); }
    }
  };

  if (posts.length < 1) return (
    <>
      <div class="flex items-center justify-center w-full h-[90vh]">
        <div role="status">
          <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </>
  )
  return (
    <>
      <div class="p-4 bg-white sm:p-6 dark:bg-gray-800">
        <div class="items-center justify-between lg:flex">
          <div class="mb-4 lg:mb-0">
            <h3 class="mb-2 text-xl font-bold text-gray-900 dark:text-white">Post Approval</h3>
            {/* <span class="text-base font-normal text-gray-500 dark:text-gray-400">This is a list of latest transactions</span> */}
          </div>
        </div>

        {roles.includes("admin") ? (
          <div id="sProfileModal" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div class="relative p-4 w-full max-w-md max-h-full">
              <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div class="flex items-center justify-between p-4 md:p-5 rounded-t">
                  <span href="#" class="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                    <span class="flex-1 ms-3 whitespace-nowrap">{moderatorsName} ({moderatorsUID})</span>
                  </span>
                  <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="sProfileModal">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (<></>)}
        <div class="flex flex-col mt-6">
          <div class="overflow-x-auto shadow rounded-lg">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                  <thead class="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        #
                      </th>
                      <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        Action
                      </th>
                      {roles.includes("admin") ? (
                        <>
                          <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                            Profile ID
                          </th>
                          <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                            Moderator ID
                          </th>
                        </>
                      ) : (<></>)}
                      <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        Status
                      </th>
                      <th scope="col" class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        Time Left
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white dark:bg-gray-800">
                    {posts.map((post, index) => (
                      <tr key={post._id}>
                        <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                          <Link to={{ pathname: `./${post._id}` }}>
                            <span class="font-semibold">{index + 1}</span>
                          </Link>
                        </td>
                        <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                          <Link to={{ pathname: `./${post._id}` }}>
                            <button type="button" class="py-2 px-5 me-2 mb-2 text-xs text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Open Post</button>
                          </Link>
                        </td>
                        {roles.includes("admin") ? (
                          <>
                            <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                              {post.of}
                            </td>
                            <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                              <button type='button' data-modal-target="sProfileModal" data-modal-toggle="sProfileModal" data-moderator={post.moderator} onClick={showShortProfile}>
                                {post.moderator}
                              </button>
                            </td>
                          </>
                        ) : (<></>)}
                        <td class="p-4 whitespace-nowrap">
                          <span class="rounded-full capitalize bg-orange-100 text-orange-800 text-xs font-medium mr-2 px-2.5 py-0.5 dark:bg-gray-700 dark:text-orange-400 border border-orange-100 dark:border-orange-500">{post.passToAdmin ? ('Forward') : (post.status)}</span>
                        </td>
                        <td class="p-4 text-sm font-normal text-orange-500 whitespace-nowrap dark:text-orange-400">
                          {calculateTimeLeft(post.createdAt)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-end pt-3 sm:pt-6">
          {/* <div>
            <button class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500 rounded-lg hover:text-gray-900 dark:text-gray-400 dark:hover:text-white" type="button" data-dropdown-toggle="transactions-dropdown">Last 7 days <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
            
            <div class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600 pagination" id="transactions-dropdown" data-popper-placement="top" data-popper-reference-hidden="" data-popper-escaped="">
              <div class="px-4 py-3" role="none">
                <p class="text-sm font-medium text-gray-900 truncate dark:text-white" role="none">
                  Sep 16, 2021 - Sep 22, 2021
                </p>
              </div>
              <ul class="py-1" role="none">
                <li>
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Yesterday</a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Today</a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Last 7 days</a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Last 30 days</a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Last 90 days</a>
                </li>
              </ul>
              <div class="py-1" role="none">
                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Custom...</a>
              </div>
            </div>
          </div> */}
          {roles.includes("admin") ? (
            <div class="flex-shrink-0">
              <Link class="inline-flex items-center p-2 text-xs font-medium uppercase rounded-lg text-primary-700 sm:text-sm hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-700">
                Approval Report
                <svg class="w-4 h-4 ml-1 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
              </Link>
            </div>
          ) : (<></>)}
        </div>
      </div>
    </>
  );
};


Moderate.propTypes = {};

Moderate.defaultProps = {};

export default Moderate;
