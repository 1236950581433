import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { accessToken, apiBaseUrl, AuthVerify } from '../../modules/main';
import './setPassword.css';
import axios from 'axios';

const SetPassword = ({ setBreadcrumb, setBreadcrumbLink, setErrorMessage, setSuccessMessage, setNetworkErrorMessage }) => {
  useEffect(() => {
    setBreadcrumb(['Settings', 'Set Password']);
    setBreadcrumbLink(['/dashboard/settings', '/dashboard/settings/setpassword']);
  }, [setBreadcrumb, setBreadcrumbLink]);

  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    if (accessToken === null) {
      navigate('/');
    }
    if (sessionStorage.getItem('status') !== 'pending') {
      navigate('/dashboard');
    }
  }, [navigate])

  const changePassword = async () => {
    try {
      if (password !== '') {
        if (confirmPassword === password) {
          await AuthVerify().then(async response => {
            if (response) {
              // Make a request to add a new post
              let res = await axios.patch(
                `${apiBaseUrl}/moderator/login/setpassword`,
                {
                  password: password,
                  confirmPassword: confirmPassword
                },
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              );
              if (!res.data.error) {
                sessionStorage.removeItem('status');
                setSuccessMessage('Successfully updated Password.');
                navigate('/dashboard');
                setPassword('');
                setConfirmPassword('');
              } else {
                setErrorMessage(res.data.message)
              }
            }

          });
        } else {
          setErrorMessage(`Password and Confirm password do not match`);
        }
      } else {
        setErrorMessage(`Password cannot be empty.`);
      }
    } catch (error) {
      console.error('Error adding post:', error);
    }
  };
  const handelSetPassword = (event) => {
    event.preventDefault();
    changePassword();
  };
  return (
    <>
      <div className='p-4'>
        <div class="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
          <h3 class="mb-4 text-xl font-semibold dark:text-white">Set Password</h3>
          <form method='post' onSubmit={handelSetPassword}>
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6 sm:col-span-3">
                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New password</label>
                <input onChange={(e) => setPassword(e.target.value)} value={password} type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="••••••••" required />
              </div>
              <div class="col-span-6 sm:col-span-3">
                <label for="confirm-password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
                <input autoComplete="off" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} type="text" name="confirm-password" id="confirm-password" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" placeholder="••••••••" required />
              </div>
              <div class="col-span-6 sm:col-full">
                <button class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800" type="submit">Set Password</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
};


export default SetPassword;
