
import axios from 'axios';

let roles = JSON.parse(sessionStorage.getItem('roles'));
let accessToken = sessionStorage.getItem('accessToken');
let refreshToken = sessionStorage.getItem('refreshToken');

const apiBaseUrl = 'https://engageapi.webhizzy.in';
// const apiBaseUrl = 'http://localhost:8080';

const AuthVerify = async () => {
  const decodedJwtRefresh = JSON.parse(atob((refreshToken).split(".")[1]));
  if (accessToken !== null && refreshToken !== null && decodedJwtRefresh.exp * 1000 > Date.now()) {
    const decodedJwt = JSON.parse(atob((accessToken).split(".")[1]));
    if (decodedJwt.exp * 1000 > Date.now()) {
      return true;
    } else {
      try {
        const response = await axios.post(
          `${apiBaseUrl}/token`,
          { refreshToken: refreshToken },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        var newAccessToken = response.data.accessToken;
        accessToken = newAccessToken;
        sessionStorage.setItem('accessToken', newAccessToken);
        return true;
      } catch (error) {
        return false;
      }
    }
  } else {
    return false;
  }
};

const handleLogout = async () => {
  try {
    const res  = await axios.post(`${apiBaseUrl}/logout`,{refreshToken});
    if(res.data.error){
      alert(res.data.message);
    }
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('status');
    sessionStorage.removeItem('roles');
    accessToken = null;
    refreshToken = null;
    roles = null;
  } catch (error) {
    console.error('Logout failed:', error);
  }
};

const isLoggedIn = () => {
  const storedRefreshToken = sessionStorage.getItem('refreshToken');
  const storedAccessToken = sessionStorage.getItem('accessToken');
  if (storedAccessToken !== null && storedRefreshToken !== null) {
    return true;
  } else {
    return false;
  }
}

export { roles, accessToken, AuthVerify, apiBaseUrl, isLoggedIn, handleLogout }