import React from 'react';
import './allDone.css';
import { Link } from 'react-router-dom';
const AllDone = () => {

  return (
    <>
      <div class="flex flex-col justify-center items-center px-6 mx-auto h-[90vh] xl:px-0 dark:bg-gray-900">
        <div class="block md:max-w-lg mb-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className='w-96'
            data-name="Layer 1"
            version="1.1"
            viewBox="0 0 800.122 541.445"
          >
            <path
              fill="#f2f2f2"
              d="M931.831 368.723c-.84.69-1.68 1.35-2.54 2h-212.14q-2.37-.975-4.72-2a249.807 249.807 0 01-61.87-38.52c-12.37-10.48-23.75-21.3-32-31.44-11.51-14.15-22.11-29.57-27.04-47.14-4.92-17.57-3.45-37.71 7.54-52.28a46.795 46.795 0 0112.35-11.31c.79-.5 1.59-.98 2.41-1.43 13.18-7.4 29.81-9.2 44.65-5.37 21.25 5.49 38.91 20.82 51.51 38.8 12.6 17.97 20.78 38.61 28.84 59.02 8.18-36.43 32.95-69.06 66.41-85.65 33.45-16.59 75.01-16.39 107.75 1.58a108.292 108.292 0 0127.55 21.7c.79.85 1.56 1.72 2.32 2.6 15.97 18.49 25.96 42.17 26.78 66.59 1.05 31.46-13.53 63.06-37.8 82.85z"
              transform="translate(-199.939 -179.277)"
            ></path>
            <path
              fill="#fff"
              d="M725.63 370.723h-3c-.039-.67-.089-1.33-.149-2a224.18 224.18 0 00-6.56-38.74 238.817 238.817 0 00-38.31-80.46 228.6 228.6 0 00-65.5-60.46 1.42 1.42 0 01-.7-1.03 1.496 1.496 0 012.21-1.56 2.256 2.256 0 01.2.13 226.457 226.457 0 0140.12 31.12 240.898 240.898 0 0151.71 74.13 233.592 233.592 0 0119.82 76.87c.06.67.11 1.34.16 2z"
              transform="translate(-199.939 -179.277)"
            ></path>
            <path
              fill="#fff"
              d="M942.851 219.283a.184.184 0 01-.07.02c-2.19.4-4.39.82-6.57 1.27a296.84 296.84 0 00-52.33 15.89 308.808 308.808 0 00-48.5 25 313.784 313.784 0 00-43.45 33.08 307.525 307.525 0 00-36.99 40.12 297.483 297.483 0 00-22.61 34.06c-.38.66-.75 1.33-1.12 2h-3.43c.36-.67.73-1.34 1.11-2a299.923 299.923 0 0127.98-41.33 310.84 310.84 0 0138.31-39.75 317.84 317.84 0 0144.38-32.28 311.853 311.853 0 0149.52-24.16 297.104 297.104 0 0151.45-14.52l1.46-.27c1.87-.34 2.67 2.48.86 2.87z"
              transform="translate(-199.939 -179.277)"
            ></path>
            <path
              fill="#2f2e41"
              d="M397.619 376.668c5.334-10.071 6.215-23.188-.418-32.455-3.95-5.516-10.114-9.137-16.584-11.18s-13.293-2.642-20.059-3.138c-6.697-.492-13.514-.885-20.056.63s-12.88 5.215-16.066 11.126c-5.384 9.985-.48 22.79-4.434 33.422-3.63 9.758-13.958 15.463-24.11 17.765s-20.75 2.057-30.909 4.337-20.503 7.932-24.197 17.665c-2.67 7.039-1.344 15.146 2.176 21.802s9.03 12.043 14.88 16.78c8.729 7.07 18.94 13.123 30.154 13.736 11.816.645 23.37-4.98 31.875-13.208s14.295-18.863 18.92-29.755c2.935-6.91 5.708-14.347 11.589-19.013 4.321-3.429 9.818-4.93 15.148-6.352l6.036-1.608a41.44 41.44 0 0025.894-20.253l.16-.301z"
              transform="translate(-199.939 -179.277)"
            ></path>
            <path
              fill="#ffb8b8"
              d="M150.079 528.665L137.82 528.665 131.987 481.377 150.082 481.377 150.079 528.665z"
            ></path>
            <path
              fill="#2f2e41"
              d="M129.062 525.162h23.644v14.887h-38.53a14.887 14.887 0 0114.886-14.887z"
            ></path>
            <path
              fill="#ffb8b8"
              d="M252.079 528.665L239.82 528.665 233.987 481.377 252.082 481.377 252.079 528.665z"
            ></path>
            <path
              fill="#2f2e41"
              d="M231.062 525.162h23.644v14.887h-38.53a14.887 14.887 0 0114.886-14.887z"
            ></path>
            <path
              fill="#2f2e41"
              d="M373.585 670.357a4.834 4.834 0 01-.512-.027l-49.878-5.344a4.69 4.69 0 01-4.063-5.751l44.163-167.97a4.718 4.718 0 013.545-3.493l40.968-11.42a4.722 4.722 0 014.549 1.482c26.34 29.71 46.19 95.571 61.227 178.585a4.692 4.692 0 01-4.022 5.49l-40.868 5.218a4.678 4.678 0 01-5.17-3.627l-19.884-88.648a3.648 3.648 0 00-7.138.082l-18.33 91.651a4.666 4.666 0 01-4.587 3.772z"
              transform="translate(-199.939 -179.277)"
            ></path>
            <circle cx="166.059" cy="195.894" r="24.561" fill="#ffb8b8"></circle>
            <path
              fill="#ccc"
              d="M365.312 491.737a4.68 4.68 0 01-4.521-3.486l-14.776-55.51a16.778 16.778 0 0110.11-19.964c11.894-4.643 23.226-6.365 33.68-5.12 9.292 1.107 20.755 56.117 19.997 57.291l3.666 11a4.69 4.69 0 01-3.442 6.065l-43.695 9.612a4.732 4.732 0 01-1.019.112z"
              transform="translate(-199.939 -179.277)"
            ></path>
            <path
              fill="#ffb8b8"
              d="M378.554 548.504a7.61 7.61 0 00-7.368-9.05l-8.513-25.67-8.7 11.037 9.418 22.685a7.652 7.652 0 0015.163.998z"
              transform="translate(-199.939 -179.277)"
            ></path>
            <path
              fill="#ffb8b8"
              d="M488.002 486.811a7.61 7.61 0 00-11.385-2.561l-22.556-14.922-.02 14.054 21.422 12.015a7.652 7.652 0 0012.539-8.586z"
              transform="translate(-199.939 -179.277)"
            ></path>
            <path
              fill="#ccc"
              d="M360.707 536.644a4.69 4.69 0 01-4.454-3.218l-19.957-42.217a24.399 24.399 0 01-1.235-7.669l6-54.87a12.059 12.059 0 0124.03-1.457l-9.18 62.631 14.649 38.44a4.681 4.681 0 01-2.478 5.225l-5.278 2.64a4.685 4.685 0 01-2.097.495z"
              transform="translate(-199.939 -179.277)"
            ></path>
            <path
              fill="#ccc"
              d="M467.29 492.354a4.681 4.681 0 01-2.114-.504l-39.688-20.047a24.368 24.368 0 01-6.29-4.56l-38.736-38.863a12.059 12.059 0 0115.992-17.994l41.963 46.603 33.263 21.12a4.68 4.68 0 011.933 5.45l-1.875 5.596a4.692 4.692 0 01-4.448 3.199z"
              transform="translate(-199.939 -179.277)"
            ></path>
            <path
              fill="#2f2e41"
              d="M338.061 362.473a28.6 28.6 0 0016.895 10.631 29.87 29.87 0 0020.06-2.874c4.947-2.673 8.905-6.684 12.782-10.633 1.618-1.648 3.342-3.55 3.26-5.798-.126-3.483-4.314-5.388-7.908-6.148a56.435 56.435 0 00-35.033 3.94c-4.88 2.267-9.965 6.368-9.232 11.466"
              transform="translate(-199.939 -179.277)"
            ></path>
            <path
              fill="#3f3d56"
              d="M983.061 368.723h-482a17.024 17.024 0 00-17 17v186a17.024 17.024 0 0017 17h482a17.024 17.024 0 0017-17v-186a17.024 17.024 0 00-17-17zm15 203a15.018 15.018 0 01-15 15h-482a15.018 15.018 0 01-15-15v-186a15.018 15.018 0 0115-15h482a15.018 15.018 0 0115 15z"
              transform="translate(-199.939 -179.277)"
            ></path>
            <path
              fill="#3f3d56"
              d="M588.575 536.723a58 58 0 1158-58 58.066 58.066 0 01-58 58zm0-114a56 56 0 1056 56 56.064 56.064 0 00-56-56z"
              transform="translate(-199.939 -179.277)"
            ></path>
            <path
              fill="#f68220"
              d="M940.047 441.723h-231a12.497 12.497 0 00-12.48 12.01 4.01 4.01 0 00-.02.49 12.518 12.518 0 0012.5 12.5h231a12.5 12.5 0 000-25z"
              transform="translate(-199.939 -179.277)"
            ></path>
            <path
              fill="#f68220"
              d="M940.047 490.723h-231a12.497 12.497 0 00-12.48 12.01 4.01 4.01 0 00-.02.49 12.518 12.518 0 0012.5 12.5h231a12.5 12.5 0 000-25z"
              transform="translate(-199.939 -179.277)"
            ></path>
            <path
              fill="#f68220"
              d="M582.104 504.064a7.46 7.46 0 01-4.489-1.49l-.08-.06-16.904-12.943a7.51 7.51 0 119.135-11.923l10.95 8.396L606.59 452.3a7.51 7.51 0 0110.53-1.39l-.161.218.165-.215a7.518 7.518 0 011.39 10.53l-30.435 39.69a7.513 7.513 0 01-5.975 2.931z"
              transform="translate(-199.939 -179.277)"
            ></path>
            <path
              fill="#fff"
              d="M883.197 323.672a198.228 198.228 0 00-62.845 1.417 202.342 202.342 0 00-59.173 20.688 195.2 195.2 0 00-29.53 19.51c-1.507 1.205.628 3.315 2.122 2.121a193.808 193.808 0 0153.787-30.235 199.647 199.647 0 0160.064-12.186 191.075 191.075 0 0134.777 1.578 1.554 1.554 0 001.845-1.048 1.51 1.51 0 00-1.047-1.845z"
              transform="translate(-199.939 -179.277)"
            ></path>
          </svg>
        </div>
        <div class="text-center xl:max-w-4xl">
          <h1 class="mb-3 text-2xl font-bold leading-tight text-gray-900 sm:text-4xl lg:text-5xl dark:text-white">You've Conquered All!</h1>
          <p class="mb-5 text-base font-normal text-gray-500 md:text-lg dark:text-gray-400">No more posts are allocated for you.</p>
          <Link to={'/dashboard/jobs'} class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-3 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
            Refresh
          </Link>
        </div>
      </div>
    </>
  );
}

export default AllDone;
