import { initFlowbite, Modal } from 'flowbite';

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { apiBaseUrl } from '../../modules/main';
import Logo from '../../logo.webp';
import './login.css';
import { Link } from 'react-router-dom';
const Login = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [proceeding, setProceeding] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [networkErrorMessage, setNetworkErrorMessage] = useState('');

  useEffect(() => {
    const modal = new Modal(document.getElementById('error-modal'));
    if (errorMessage !== '') {
      modal.show();
    } else {
      modal.hide();
    }
  }, [errorMessage])

  useEffect(() => {
    const modal = new Modal(document.getElementById('errorModalNetworkError'));
    if (networkErrorMessage !== '') {
      modal.show();
    } else {
      modal.hide();
    }
  }, [networkErrorMessage])

  const closeErrorMessage = () => { setErrorMessage(''); }

  const reloadPage = () => { window.location.reload(); }

  const onLogin = async (uid, password) => {
    try {
      if (username === '' || password === '') {
        setErrorMessage('Please enter Login details.')
      } else {
        setProceeding(true)
        setErrorMessage('');
        const response = await axios.post(`${apiBaseUrl}/moderator/login`, { uid, password });
        if (!response.data.error) {
          sessionStorage.setItem('roles', JSON.stringify(response.data.roles));
          sessionStorage.setItem('accessToken', response.data.accessToken);
          sessionStorage.setItem('refreshToken', response.data.refreshToken);
          window.location.reload();
        } else {
          setProceeding(null)
          setErrorMessage(response.data.message);
        }
      }
    } catch (error) {
      setProceeding(null)
      if (error.message === 'Network Error') {
        setNetworkErrorMessage('No Internet Connection.');
      }else{
        console.error(`Error: ${error}`);
      }
    }
  };

  useEffect(() => {
    initFlowbite()
  }, [])

  const handleLogin = (event) => {
    event.preventDefault();

    onLogin(username, password);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center px-6 pt-8 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900">
        <a href="/" className="flex items-center justify-center mb-8 text-2xl font-semibold lg:mb-10 dark:text-white">
          <img src={Logo} className="mr-4 h-11" alt="LPU Engage" />
        </a>


        <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
          <div className="w-full p-6 sm:p-8">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
              Sign In
            </h2>
            <form className="mt-8 space-y-6" method='post' onSubmit={handleLogin}>
              <div>
                <label htmlFor="uid" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">UID</label>
                <input type="number" name="uid" value={username}
                  onChange={(e) => setUsername(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" placeholder="60000" required="" />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                <input type="password" name="password" value={password}
                  onChange={(e) => setPassword(e.target.value)} placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" required="" />
              </div>
              <div className="flex items-start">
                {/* <div className="flex items-center h-5">
                <input id="remember" aria-describedby="remember" name="remember" type="checkbox" className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-gray-300 dark:focus:ring-gray-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" disabled />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="remember" className="font-medium text-gray-900 dark:text-white">Remember me</label>
              </div> */}
                <Link to={'/emailLogin'} className="text-sm text-gray-700 hover:underline dark:text-gray-500">Sign In with OTP</Link>
              </div>
              <button type="submit" className="w-full px-5 py-3 text-base font-medium text-center text-white bg-gray-700 rounded-lg hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 sm:w-auto dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                {proceeding ? (<>
                  <div className='inline mr-3' role="status">
                    <svg aria-hidden="true" className="w-5 h-5 inline text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                </>) : (<></>)}
                Sign In
              </button>
            </form>
          </div>
        </div>
      </div>

      <div id="error-modal" tabindex="-1" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full" aria-modal="true" role="dialog" >
        <div class="relative p-4 w-full max-w-sm max-h-full">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="p-4 md:p-5 text-center">
              <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{errorMessage}</h3>
              <button data-modal-hide="error-modal" onClick={closeErrorMessage} type="button" class="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-bold rounded-lg text-sm w-full items-center px-5 py-2.5 text-center me-2">
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="errorModalNetworkError" tabindex="-1" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full" aria-modal="true" role="dialog" >
        <div class="relative p-4 w-full max-w-sm max-h-full">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="p-4 md:p-5 text-center">
              <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{networkErrorMessage}</h3>
              <button data-modal-hide="errorModalNetworkError" onClick={reloadPage} type="button" class="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-bold rounded-lg text-sm w-full items-center px-5 py-2.5 text-center me-2">
                Reload Page
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Login;
