import './addPost.css'; import { initFlowbite } from 'flowbite';

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { accessToken, AuthVerify, handleLogout, apiBaseUrl } from '../../modules/main';


import { useDropzone } from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const AddPost = () => {
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState('Volunteer');
  const [subCategory, setSubCategory] = useState('Colony Level Volunteership');
  const [location, setLocation] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);
  const [currentCrop, setCurrentCrop] = useState(null); // Currently selected crop
  const [cropParams, setCropParams] = useState({ aspect: 4 / 5 });

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleImageLoaded = (image) => {
    // You can set initial crop size and position here
  };

  const handleCompleteCrop = (crop) => {
    if (crop.width && crop.height) {
      const croppedImageUrl = getCroppedImageUrl(files[currentCrop], crop);
      // Update the file list with the cropped image URL for preview or upload
      let updatedFiles = [...files];
      updatedFiles[currentCrop] = Object.assign(updatedFiles[currentCrop], { croppedPreview: croppedImageUrl });
      setFiles(updatedFiles);
    }
  };

  // Converts crop rectangle to actual image URL
  const getCroppedImageUrl = (file, crop) => {
    // This function should create a new image URL based on cropping parameters.
    // You'll need to implement this based on `react-image-crop` documentation or use the canvas method to draw and convert to blob then to URL.
    return file.preview; // Placeholder return
  };

  const uploadImages = async () => {
    // Placeholder for upload logic
    // Iterate over `files` and use `axios` to upload each.
    // Ensure you use the cropped image data.
  };
  const changeOptions = (selected) => {

    const VolunteerArr = ['Colony Level Volunteership', 'District Level Volunteership', 'State Level Volunteership', 'National Level Volunteership', 'International Level Volunteership'];
    const AcademicianArr = ['Teacher', 'Professor', 'Guide'];
    const LeaderArr = ['Colony Level Leadership', 'District Level Leadership', 'State Level Leadership', 'National Level Leadership', 'International Level Leadership'];
    const AchieverArr = ['University Level achievement', 'State Level Achievement', 'National Level Achievement', 'International Level Achievement'];

    let type = VolunteerArr;
    if (selected === "Academician") {
      type = AcademicianArr;
    } else if (selected === "Leader") {
      type = LeaderArr;
    } else if (selected === "Achiever") {
      type = AchieverArr;
    }
    var options = type.map((el) => <option key={el}>{el}</option>);
    return options;
  }

  useEffect(() => {
    initFlowbite()
  }, [])

  const handleAddPost = async () => {
    try {
      if (AuthVerify()) {
        // Make a request to add a new post
        await axios.post(
          `${apiBaseUrl}/moderator/post`,
          {
            title: title,
            category: category,
            subCategory: subCategory,
            location: location,
            description: description
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        // Refresh the posts after adding a new one
        const response = await axios.get(`${apiBaseUrl}/moderator/post`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setPosts(response.data.post);

        setTitle('');
      }
    } catch (error) {
      console.error('Error adding post:', error);
    }
  };
  return (
    <>
      <div>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        <div style={{ marginTop: '20px' }}>
          {files.length > 0 && (
            <ReactCrop
              src={files[currentCrop]?.preview}
              crop={cropParams}
              onImageLoaded={handleImageLoaded}
              onComplete={handleCompleteCrop}
              onChange={newCrop => setCropParams(newCrop)}
            />
          )}
          {files.map((file, index) => (
            <div key={file.name}>
              <img src={file.preview || file.croppedPreview} alt="preview" onClick={() => setCurrentCrop(index)} style={{ width: '100px', height: 'auto' }} />
            </div>
          ))}
        </div>
        <button onClick={uploadImages}>Upload Images</button>
      </div>
      <div>
        <h3>Add New Post</h3>
        <input
          type="text"
          value={title}
          placeholder='Title'
          onChange={(e) => setTitle(e.target.value)}
        />
        <select
          type="text"
          value={category}
          placeholder='Category'
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value={'Volunteer'}>Volunteer</option>
          <option value={'Academician'}>Academician</option>
          <option value={'Leader'}>Leader</option>
          <option value={'Achiever'}>Achiever</option>
        </select>
        {

        }
        <select
          type="text"
          value={subCategory}
          placeholder='Sub Category'
          onChange={(e) => setSubCategory(e.target.value)}
        >
          {changeOptions(category)}
        </select>
        <input
          type="text"
          value={location}
          placeholder='Location'
          onChange={(e) => setLocation(e.target.value)}
        />
        <textarea
          type="text"
          value={description}
          placeholder='Description'
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
        <button onClick={handleAddPost}>Add Post</button>
      </div>
    </>
  );
};


export default AddPost;
